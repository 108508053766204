@import 'variables';
@import 'reset';

body {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	// padding: responsive(40, 60) responsive(20, 40);
	padding: responsive(20, 40);
	background-color: $pink;
	color: $blue;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

#header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	a, button {
		font-size: responsive(14, 16);
	}
}

#wrap {
	h1 {
		text-align: center;
		text-transform: uppercase;
		font-weight: 900;
		font-size: responsive(48, 144);
		line-height: responsive(48, 144);
		-webkit-text-stroke: responsive(2, 4) $blue;
		color: transparent;
	}
}

#footer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	a {
		display: block;
		margin: 0 responsive(10, 20);
		font-size: responsive(14, 16);
		position: relative;
		&:before {
			content: '';
			background-color: $blue;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			position: absolute;
			top: 50%;
			left: responsive(-14, -24);
			transform: translateY(-35%);
		}
		&:first-child {
			&::before {
				display: none;
			}
		}
	}
}

#modal {
	z-index: 2;
	background-color: $blue;
	padding: responsive(20, 40);
	position: fixed;
	top: 0;
	right: -200%;
	max-width: 500px;
	height: 100vh;
	transition: right .6s ease;
	overflow-y: scroll;
	button {
		color: $pink;
		font-size: 50px;
		line-height: .5;
		transform: rotate(45deg);
		position: fixed;
	}
	p {
		color: $pink;
		font-size: 16px;
		margin: 0 0 10px;
		line-height: 1.5;
	}
	img {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: 55%;
		overflow: hidden;
		border-radius: 100%;
	}
	h2 {
		color: $pink;
		margin: 20px 0;
		text-align: center;
	}
	&.active {
		right: 0%;
	}
}
